import { useParams } from "react-router-dom";
import React, { useState } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, Divider, View, Text, Heading, Button, TextField} from '@aws-amplify/ui-react';


export default function Userinfo({userJwt, user, getUpdatedUserData, fleetCode, displayAdministrativeFunctions}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [displayUpdateLicense, updateDisplayUpdateLicense] = React.useState(false);
  const [license_number, updateLicenseNumber] = React.useState("");
  const [license_state, updateLicenseState] = React.useState("");

  async function overrideLicenseNumber() {
    updateIsLoading(true)
    updateDisplayUpdateLicense(false)
    var t_license_number = license_number;
    var t_license_state = license_state;
    if(t_license_number=='')
    {
      t_license_number = user.resp[0]["license"];
    }
    if(t_license_state=='')
    {
      t_license_state = user.resp[0]["license_state"];
    }
    const myInit = {queryStringParameters:{ 
        "back_end": process.env.REACT_APP_BACKEND,
        "jwt":userJwt,
        "api_endpoint":"licensenumberoverride",
        "driver_id":user.resp[0]["id"],
        "license_number":t_license_number,
        "license_state":t_license_state,
        "fleet_code":fleetCode
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        getUpdatedUserData();
    }
    catch (e) {
        console.log(toString(e))
    }
    updateIsLoading(false)

  }

  return (
    is_loading ? <Loadingmini /> : 
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
          <Divider />
          {
            user ?
              user.resp ?
                user.resp[0] ?  
                    <Flex direction="column">
                      <table>
                        <tbody>
                        {
                          user.resp[0] ?
                              user.resp[0]["name_first"] ? 
                                  user.resp[0]["name_first"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>First Name:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["name_first"]}</td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["name_middle"] ? 
                                  user.resp[0]["name_middle"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Middle Name:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["name_middle"]}</td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["name_last"] ? 
                                  user.resp[0]["name_last"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Last Name:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["name_last"]}</td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["phone"] ? 
                                  user.resp[0]["phone"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Phone:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["phone"]}</td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["email"] ? 
                                  user.resp[0]["email"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Email:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["email"]}</td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          displayUpdateLicense ?
                            <View>

                              <Divider/>

                              <TextField
                                placeholder='license number'
                                onInput={(e) => updateLicenseNumber(e.target.value)}
                                defaultValue={user.resp[0]["license"]}
                              ></TextField>
                              
                              <TextField
                                placeholder='license state'
                                onInput={(e) => updateLicenseState(e.target.value)}
                                defaultValue={user.resp[0]["license_state"]}
                              ></TextField>

                              <br/>
                              <Button onClick={() => overrideLicenseNumber()}>
                                  SUBMIT UPDATE LICENSE
                              </Button>

                              <br/><br/>
                              <Button onClick={() => updateDisplayUpdateLicense(false)}>
                                  CANCEL UPDATE LICENSE
                              </Button>

                              <Divider/>

                            </View>
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["license"] ? 
                                  displayUpdateLicense === false?
                                    <tr>
                                      <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>License:</th>
                                      <td style={displayAdministrativeFunctions ? {paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px', color:'#0000EE', textDecoration:'underline'} : {paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{displayAdministrativeFunctions === false ? user.resp[0]["license"] : <a onClick={() => updateDisplayUpdateLicense(true)}>{user.resp[0]["license"]}</a>}</td>
                                    </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["license_state"] ? 
                                  displayUpdateLicense === false ?
                                    <tr>
                                      <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>State:</th>
                                      <td style={displayAdministrativeFunctions ? {paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px', color:'#0000EE', textDecoration:'underline'} : {paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{displayAdministrativeFunctions === false ? user.resp[0]["license_state"] : <a onClick={() => updateDisplayUpdateLicense(true)}>{user.resp[0]["license_state"]}</a>}</td>
                                    </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        
                        {
                          user.resp[0] ?
                              user.resp[0]["dob"] ? 
                                  user.resp[0]["dob"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Date of Birth:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["dob"]}</td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["stripe_user_card"] ? 
                                  user.resp[0]["stripe_user_card"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Stripe Customer:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}><a href={'https://dashboard.stripe.com/customers/' + user.resp[0]["stripe_user_card"]} target='_blank'>{user.resp[0]["stripe_user_card"]}</a></td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["checkr_mvr_status"] ? 
                                  user.resp[0]["checkr_mvr_status"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>MVR Status:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["checkr_mvr_status"]}</td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["checkr_candidate_id"] ? 
                                  user.resp[0]["checkr_candidate_id"] !== "" && user.resp[0]["checkr_candidate_id"] !== "0" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>CheckR Candidate:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}><a href={'https://dashboard.checkr.com/candidates/' + user.resp[0]["checkr_candidate_id"]} target='_blank'>{user.resp[0]["checkr_candidate_id"]}</a></td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["cognito_id"] ? 
                                  user.resp[0]["cognito_id"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Cognito ID:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["cognito_id"]}</td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["id"] ? 
                                  <tr>
                                    <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>User ID:</th>
                                    <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["id"]}</td>
                                  </tr>
                              : undefined
                          : undefined
                        }
                        {
                          user.resp[0] ?
                              user.resp[0]["sql_timestamp"] ? 
                                  user.resp[0]["sql_timestamp"] !== "" ? 
                                      <tr>
                                        <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Joined:</th>
                                        <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["sql_timestamp"]}</td>
                                      </tr>
                                  : undefined
                              : undefined
                          : undefined
                        }
                        </tbody>
                      </table>
                    </Flex>
                : 
                  <Flex direction="column">
                    <Text>Error loading user data</Text>
                    <Text>No data found for user</Text>
                  </Flex>
              : 
                <Flex direction="column">
                  <Text>Error loading user data</Text>
                  <Text>No user data</Text>
                </Flex>
            : 
              <Flex direction="column">
                <Text>Error loading user data</Text>
                <Text>User not found</Text>
              </Flex>
          }
        </View>
      </Flex>
  );
}
