/* src/App.js */

import {Amplify,API,Auth} from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
import {  Authenticator, useTheme, View, Image, Flex, Heading, Text} from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import {  Outlet, Link } from "react-router-dom";
import { FaDollarSign, FaFileInvoiceDollar, FaMoneyCheckAlt, FaTable, FaUserEdit,FaIdCardAlt, FaLandmark, FaCloud, FaCloudUploadAlt, FaTools, FaWpforms, FaListAlt, FaCar, FaBars, FaTachometerAlt, FaHome, FaUser, FaIdCard, FaUsers, FaComment, FaFileSignature, FaCogs } from 'react-icons/fa';
import { SidebarHeader, ProSidebar, Menu, MenuItem, SubMenu, SidebarFooter } from 'react-pro-sidebar';
import './styles/override-custome-pro-sidebar.scss';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Ago logo"
          src="https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/ago_logov62_267.png"
        />
      </View>
    );
  },
}

const App = () => {
  const [sidebarCollapsed, updateSidebarCollapsed] = useState(true);
  function toggleSidebarCollapsed()
  {
    if(sidebarCollapsed===true)
    {
      updateSidebarCollapsed(false);
    } else {
      updateSidebarCollapsed(true);
    }
  }
  function openSidebarCollapsed()
  {
    if(sidebarCollapsed===true)
    {
      updateSidebarCollapsed(false);
    }
  }
  const { tokens } = useTheme();

  
  async function checkUserTable(user) {
    //console.log('init')
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"user",
            "method":"check_user_table",
            "phone": user.attributes.phone_number,
            "email": user.attributes.email,
            "cognito_id": user.attributes.sub,
        }};
        await API.get('adminWebTools','/ago',myInit)
    }
    catch (e) {
        // nothing now
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      Auth.currentAuthenticatedUser({
        bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(
          (user) => {
            checkUserTable(user);
          }
        )
        .catch((err) => console.log(err));
    }
    fetchUser();
  }, []);

  var SupportSubMenu = 
  <View>
    <MenuItem icon={<FaHome />}><Link to="/">Alerts</Link></MenuItem>
    <MenuItem icon={<FaListAlt />}><Link to="/reservations">Reservations</Link></MenuItem>
    <MenuItem icon={<FaUsers />}><Link to="/users">Drivers</Link></MenuItem>
    <MenuItem icon={<FaCar />}><Link to="/vehicles">Vehicles</Link></MenuItem>
    <SubMenu icon={<FaCogs />} title='Advanced' onClick={() => openSidebarCollapsed()}>
      <MenuItem icon={<FaWpforms />}><Link to="/dispute">Pay Dispute</Link></MenuItem>
      <MenuItem icon={<FaFileInvoiceDollar />}><Link to="/vehiclesutilization">Fleet Utilization</Link></MenuItem>
    </SubMenu>
  </View>

  var FleetSubMenu = 
  <View>
    <MenuItem icon={<FaHome />}><Link to="/">Alerts</Link></MenuItem>
    <MenuItem icon={<FaListAlt />}><Link to="/reservations">Reservations</Link></MenuItem>
    <MenuItem icon={<FaUsers />}><Link to="/users">Drivers</Link></MenuItem>
    <MenuItem icon={<FaCar />}><Link to="/vehicles">Vehicles</Link></MenuItem>
    <MenuItem icon={<FaTable />}><Link to="/fleetwidesummary">Fleetwide Summary</Link></MenuItem>
    <MenuItem icon={<FaMoneyCheckAlt />}><Link to="/billinghistory">Billing History</Link></MenuItem>
    <SubMenu icon={<FaCogs />} title='Advanced' onClick={() => openSidebarCollapsed()}>
      <MenuItem icon={<FaWpforms />}><Link to="/dispute">Pay Dispute</Link></MenuItem>
      <MenuItem icon={<FaFileInvoiceDollar />}><Link to="/vehiclesutilization">Fleet Utilization</Link></MenuItem>
    </SubMenu>
  </View>

  var AdminSubMenu = 
  <View>
    <MenuItem icon={<FaHome />}><Link to="/">Alerts</Link></MenuItem>
    <MenuItem icon={<FaListAlt />}><Link to="/reservations">Reservations</Link></MenuItem>
    <MenuItem icon={<FaUsers />}><Link to="/users">Drivers</Link></MenuItem>
    <MenuItem icon={<FaCar />}><Link to="/vehicles">Vehicles</Link></MenuItem>
    <SubMenu icon={<FaCogs />} title='Advanced' onClick={() => openSidebarCollapsed()}>
      <MenuItem icon={<FaWpforms />}><Link to="/dispute">Pay Dispute</Link></MenuItem>
      <MenuItem icon={<FaFileInvoiceDollar />}><Link to="/vehiclesutilization">Fleet Utilization</Link></MenuItem>
    </SubMenu>
  </View>

  var DriverSubMenu = 
  <View>
    <MenuItem icon={<FaHome />}><Link to="/dashboard">Dashboard</Link></MenuItem>
    <MenuItem icon={<FaTachometerAlt />}><Link to="/recenttrips">Recent Trips</Link></MenuItem>
    <MenuItem icon={<FaListAlt />}><Link to="/gigintegrations">Gig Integrations</Link></MenuItem>
  </View>

  var DriverAccountMenu =
  <View>
    <MenuItem icon={<FaLandmark />}><Link to="/fleetassignment">Join a Fleet</Link></MenuItem>
    <MenuItem icon={<FaIdCard />}><Link to="/uploadlicense">Update License</Link></MenuItem>
    <MenuItem icon={<FaIdCardAlt />}><Link to="/uploadlicenseback">Update License Back</Link></MenuItem>
  </View>

  var DriverAgreementsMenu =
  <SubMenu icon={<FaFileSignature />} title='Agreements'>
    <View>
      <MenuItem icon={<FaFileSignature />}><Link to="/agreements/fcra">FCRA Rights</Link></MenuItem>
      <MenuItem icon={<FaFileSignature />}><Link to="/agreements/disclosure">Disclosure</Link></MenuItem>
      <MenuItem icon={<FaFileSignature />}><Link to="/agreements/authorization">Authorization</Link></MenuItem>
      <MenuItem icon={<FaFileSignature />}><Link to="/esign">E-Sign</Link></MenuItem>
      <MenuItem icon={<FaFileSignature />}><Link to="/terms">Terms</Link></MenuItem>
    </View>
  </SubMenu>

  var InsuranceSubMenu = 
  <View>
    <MenuItem icon={<FaCar />}><Link to="/vehicles">Vehicles</Link></MenuItem>
    <MenuItem icon={<FaUsers />}><Link to="/users">Drivers</Link></MenuItem>
  </View>


  return (
      <Authenticator components={components}>
        {({ signOut, user }) => (
            <Flex direction="row">
              <ProSidebar collapsed={sidebarCollapsed} collapsedWidth='80px'>
                <SidebarHeader>
                  <View textAlign="center" padding={tokens.space.large}>
                    <Image
                      alt="Ago logo"
                      src="https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/ago_logov62_267.png"
                      onClick={() => toggleSidebarCollapsed()}
                    />     
                    {sidebarCollapsed ? undefined : <Text style={{color:"#adadad", fontWeight:'bold', 'fontSize': 18, paddingTop: '7px'}}>Dual Telemetry Risk Analytics</Text>}
                  </View>
                </SidebarHeader>
                <Menu>
                    {user.signInUserSession.accessToken.payload["cognito:groups"] === undefined ? DriverSubMenu : 
                    user.signInUserSession.accessToken.payload["cognito:groups"][0] ==='admin' ? AdminSubMenu : 
                    user.signInUserSession.accessToken.payload["cognito:groups"][0] ==='fleet' ? FleetSubMenu : 
                    user.signInUserSession.accessToken.payload["cognito:groups"][0] ==='insurer' ? InsuranceSubMenu : 
                    user.signInUserSession.accessToken.payload["cognito:groups"][0] ==='support' ? SupportSubMenu : DriverSubMenu}
                    {user.signInUserSession.accessToken.payload["cognito:groups"] === undefined ? 
                    DriverAgreementsMenu
                    : undefined}

                  {/*user.signInUserSession.accessToken.payload["cognito:groups"] === undefined ? undefined : user.signInUserSession.accessToken.payload["cognito:groups"][0] === 'fleet' ? <SubMenu icon={<FaCloud />} title='Schedules' onClick={() => openSidebarCollapsed()}>
                    <View>
                      <MenuItem icon={<FaListAlt />}><Link to="/fleetschedule">Fleet Schedule</Link></MenuItem>
                      <MenuItem icon={<FaCloudUploadAlt />}><Link to="/uploadschedule">Upload Schedule</Link></MenuItem>
                      <MenuItem icon={<FaCloud />}><Link to="/scheduleuploads">Schedule Uploads</Link></MenuItem>
                    </View>
                  </SubMenu>
                  : undefined*/}


                  <SubMenu icon={<FaUser />} title='Account' onClick={() => openSidebarCollapsed()}>
                      {user.signInUserSession.accessToken.payload["cognito:groups"] === undefined ? 
                        <MenuItem icon={<FaUserEdit />}><Link to="/accountinfo">Edit Account Info</Link></MenuItem>
                      : <MenuItem icon={<FaUserEdit />}><Link to="/accountinfoalt">Edit Account Info</Link></MenuItem>}
                      {user.signInUserSession.accessToken.payload["cognito:groups"] === undefined ? 
                        DriverAccountMenu
                      : undefined}
                      {user.signInUserSession.accessToken.payload["cognito:groups"] === undefined ? 
                        <MenuItem icon={<FaFileSignature />}><Link to="/terms">Terms</Link></MenuItem>
                      : undefined}
                      <br/>
                      <MenuItem>
                        <p>{user.signInUserSession.idToken.payload.email}<br/>{user.attributes['phone_number']}<br/>{user.signInUserSession.accessToken.payload["cognito:groups"] === undefined ? "driver" : user.signInUserSession.accessToken.payload["cognito:groups"][0]}</p>
                      </MenuItem>
                      <MenuItem> 
                        <button onClick={signOut} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5}}>Sign out</button>
                      </MenuItem>
                  </SubMenu>
                  <br/>
                  <br/>
                  <br/>
                  <SidebarFooter> 
                    <MenuItem icon={<FaBars />} onClick={() => toggleSidebarCollapsed()} >Close Drawer</MenuItem>
                  </SidebarFooter>
                </Menu>
              </ProSidebar>
              <Outlet />
            </Flex>
        )}
      </Authenticator>
  );
}

export default App