import React, { useState } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, View, Text, Button, Heading} from '@aws-amplify/ui-react';

export default function Vehicleregistration({vehicle, fleetCode}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [registration_url, updateRegistrationUrl] = React.useState(false);
  const [myerror, updateMyerror] = React.useState(false);
  const [showOptions, updateShowOptions] = React.useState(false);
  const [showUploadRegistration, updateShowUploadRegistration] = React.useState(false);

  async function submitRegistration() {

  }

  async function getImageUrl() {
    updateIsLoading(true)
    console.log('generating registration for car ' + vehicle['resp'][0]['car_id'] + "...");
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:jwt,
        api_endpoint:"vehicle",
        vehicle_id: vehicle['resp'][0]['car_id'],
        registration:true,
        fleet:fleetCode,
        request_type:"GET"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        if('vehicle_info' in res === false)
        {
          updateMyerror("no vehicle_info in res")
          updateIsLoading(false)
          return
        }
        if('reg_url' in res['vehicle_info'] === false)
        {
          updateMyerror("no reg_url in vehicle_info")
          updateIsLoading(false)
          return
        }
        updateRegistrationUrl(res['vehicle_info']['reg_url'])
        updateIsLoading(false)
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        updateMyerror(JSON.stringify(e));
        updateIsLoading(false)
        return
    }
  }



  return (
    is_loading ? <Loadingmini /> : 
      <Flex
        direction="column"
      >
        <View style={{width:"100%"}}>
          <Flex direction="column" gap="1rem" >
            <Flex direction="column">
                { myerror ? <View>
                          <Heading level={4}>Whoops!</Heading>
                          <Text>We ran into an error processing your request:</Text>
                          <Text>{myerror}</Text>
                        <Button onClick={() => getImageUrl()}>TRY AGAIN</Button>
                    </View>
                  :
                  registration_url ? 
                    <View>
                      <Text><a href={registration_url} target='_blank'>CLICK TO DOWNLOAD REGISTRATION</a></Text>
                      <br />
                      <Button onClick={() => updateRegistrationUrl(false)}>CANCEL</Button>
                    </View>
                  : showUploadRegistration ?
                    <View>
                      <Heading level={2}>Upload Registration</Heading>
                      <Text>In development</Text>
                      <br />
                      <Button onClick={() => submitRegistration()}>SUBMIT</Button>
                      <br />
                      <br />
                      <Button onClick={() => updateShowUploadRegistration(false)}>CANCEL</Button>
                    </View>
                  : showOptions ?
                    <View>
                      <Button onClick={() => getImageUrl()}>VIEW REGISTRATION</Button>
                      <br/>
                      <br/>
                      <Button onClick={() => updateShowUploadRegistration(true)}>UPLOAD REGISTRATION</Button>
                      <br/>
                      <br/>
                      <Button onClick={() => updateShowOptions(false)}>MINIMIZE REGISTRATION</Button>
                    </View>
                  : <Button onClick={() => updateShowOptions(true)}>REGISTRATION</Button>
                }
            </Flex>
          </Flex>
        </View>
      </Flex>
  );
}